import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import { icons } from '../helpers/SidebarHelper';

export interface IStepLink {
  text: string;
  routeUrl: string;
  icon: OverridableComponent<SvgIconTypeMap<Record<string, never>, 'svg'>>;
}

export interface IDataSteps {
  stepNum: number;
  stepTitle: string;
  stepLink?: IStepLink;
}

export const provideDataSteps: IDataSteps[] = [
  {
    stepNum: 1,
    stepTitle: 'content.home.provideDataStep.downloadTemplate',
    stepLink: {
      text: 'pages.help',
      routeUrl: '/provider/help',
      icon: icons.HelpOutlineIcon,
    },
  },
  {
    stepNum: 2,
    stepTitle: 'content.home.provideDataStep.fillTemplate',
  },
  {
    stepNum: 3,
    stepTitle: 'content.home.provideDataStep.createDataText',
    stepLink: {
      text: 'pages.createData',
      routeUrl: '/provider/create-data',
      icon: icons.AddCircleOutlineIcon,
    },
  },
  {
    stepNum: 4,
    stepTitle: 'content.home.provideDataStep.manageUploadContract',
    stepLink: {
      text: 'pages.uploadHistory',
      routeUrl: '/provider/upload-history',
      icon: icons.HistoryIcon,
    },
  },
  {
    stepNum: 5,
    stepTitle: 'content.home.consumeDataSteps.manageContracts',
    stepLink: {
      text: 'pages.contracts',
      routeUrl: '/provider/contracts',
      icon: icons.FormatListBulletedIcon,
    },
  },
];

export const consumeDataSteps: IDataSteps[] = [
  {
    stepNum: 1,
    stepTitle: 'content.home.consumeDataSteps.discoverCatalog',
    stepLink: {
      text: 'pages.consumeData',
      routeUrl: '/consumer/consume-data',
      icon: icons.ManageSearchIcon,
    },
  },
  {
    stepNum: 2,
    stepTitle: 'content.home.consumeDataSteps.subscribeDataOffer',
  },
  {
    stepNum: 3,
    stepTitle: 'content.home.consumeDataSteps.manageContracts',
    stepLink: {
      text: 'pages.contracts',
      routeUrl: '/consumer/contracts',
      icon: icons.FormatListBulletedIcon,
    },
  },
];
